.btn-primary-add,
.btn-primary-user-filter {
  Width: 202px !important;
  Height: 44px !important;
  border-radius: 0px !important;
  padding: 12px, 30px, 12px, 30px !important;
  background: #043263 !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.btn-primary-add span {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  text-transform: none !important;
}


.btn-cancelar {
  height: 44px !important;
  width: 155px !important;
  border-radius: 0px !important;
  padding: 12px, 30px, 12px, 30px !important;
  background: #FFFFFF !important;
  border: 1px solid #043263 !important;
  box-shadow: none !important;
}

.btn-cancelar span {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #043263 !important;
  text-transform: none !important;

}

.btn-primary,
.btn-primary-add-object {
  height: 44px !important;
  width: 150px !important;
  border-radius: 0px !important;
  padding: 12px, 30px, 12px, 30px !important;
  background: #043263 !important;
  box-shadow: none !important;
}

.btn-primary span {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #FFFFFF !important;
  text-transform: none !important;
}

.btn-primary-add-object span {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #FFFFFF !important;
  text-transform: none !important;
}

.btn-add-slide,
.btn-edit-slide,
.btn-eliminar-slide {
  height: 44px !important;
  width: 250px !important;
  border-radius: 0px !important;
  padding: 12px, 30px, 12px, 30px !important;
  background: #043263 !important;
  box-shadow: none !important;
}

.btn-add-slide,
.btn-edit-slide,
.btn-eliminar-slide span {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #FFFFFF !important;
  text-transform: none !important;
}

.btn-eliminar-slide {
  width: 100px !important;
  background: #E9E9E9 !important;
  color: #043263 !important;
}

.btn-eliminar-slide span {
  color: #043263 !important;
  font-size: 14px !important;
}

.btn-edit-slide span {
  font-size: 14px !important;
}

.btn-edit-slide {
  width: 150px !important;
  margin-left: 20px !important;
}


.btn-primary-help-manual-uso,
.btn-primary-help-usuario-final {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  height: 44px !important;
  border-radius: 0px !important;
  padding: 12px, 30px, 12px, 30px !important;
  background: #043263 !important;
  text-transform: none !important;
}

.btn-primary-help-manual-uso {
  width: 200px !important;
}

.btn-primary-help-usuario-final {
  width: 30% !important;
}

p {
  font-family: BBVA Benton Sans Bold !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.switch-text {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #121212 !important;
  text-align: right !important;
  margin-top: 10px;
}

.text-bbva-p {
  font-family: BBVA Benton Sans Book !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #000000 !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.text-bbva-p-title {
  font-family: BBVA Benton Sans Bold !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.pagination-bbva p {
  font-family: BBVA Benton Sans Book !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #000000 !important;
}

.input-bbva input {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000000;
}

.input-bbva label {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000000 !important;
}

h2 {
  font-family: BBVA Benton Sans Bold !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #004481 !important;
}

h6 {
  font-family: BBVA Benton Sans Bold !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000000 !important;
}

.trivia-res-bbva {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.delete-msg-dialog {
  font-family: 'BBVA Benton Sans Book !important';
  font-style: 'normal !important';
  font-size: '16px !important';
  line-height: '21px !important';
  color: #000000;
}

.text-modulos {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000000 !important;
}

.react-paginate ul li {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000000 !important;
}

.table-cell-bbva {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #121212 !important;
}

.select-input-bbva {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000000;
}

.MuiPaper-root .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000000;
}

.title-users {
  font-family: BBVA Benton Sans Bold !important;
  font-style: normal !important;
  font-size: 28px !important;
  line-height: 32px !important;
  display: flex !important;
  align-items: center !important;
  color: #004481 !important;
}

.text-center-graphic {
  font-size: 16 !important;
  text-align-last: center !important;
  color: #004481 !important;
}

.dashboard-axis-label .rv-xy-plot__axis__tick__text {
  /*   margin: 10px;
  padding: 5px; */

  font-size: 9px !important;
}

.card-subtitle-bbva-dashboard {
  font-family: BBVA Benton Sans Book !important;
  font-size: 16px;
  line-height: 21px;
  color: #121212;
}

.card-title-bbva-dashboard {
  line-height: 21px;
  color: #121212;
  padding-bottom: 25;
  font-family: BBVA Benton Sans Book !important;
  font-size: 40px;
}

.text-graphics-dashboard {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #004481 !important;
}

.makeStyles-root-170 p {
  font-family: BBVA Benton Sans Book !important;
  color: #121212 !important;
}

.css-2b097c-container div {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #121212 !important;
}

.title-dashboard {
  font-family: BBVA Benton Sans Bold !important;
  font-style: normal !important;
  font-size: 28px !important;
  line-height: 32px !important;
  color: #004481 !important;
}

.tooltip-dashboard {
  font-family: BBVA Benton Sans Book !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #000000 !important;
}

/* .textButton {
  font-family: BBVA Benton Sans Medium;
  font-style: normal;
  font-size: 1.125em;
  line-height: 24px;
  font-weight: 400;
} */

.btn-primary-disabled,
.btn-add-question {
  Width: 202px !important;
  Height: 44px !important;
  border-radius: 0px !important;
  padding: 12px, 30px, 12px, 30px !important;
  background: #CACACA !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.btn-primary-disabled span {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  text-transform: none !important;
  color: #A49797 !important;
}

.btn-add-question span {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  text-transform: none !important;
  color: #121212;
}

.btn-primary-user-filter span {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  text-transform: none !important;
  color: white !important;
}

.btn-primary-add:disabled,
.btn-primary-user-filter:disabled {
  width: 202px !important;
  height: 44px !important;
  border-radius: 0px !important;
  padding: 12px 30px !important;
  background: #e6e6e6 !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.btn-loading span {
  font-family: BBVA Benton Sans Medium;
  font-style: normal;
  font-size: 1.125em;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  text-transform: none;
}

.btn-primary,
.btn-primary-delete_quiz {
  height: 44px !important;
  width: 100px !important;
  border-radius: 0px !important;
  padding: 12px, 30px, 12px, 30px !important;
  background: #043263 !important;
  box-shadow: none !important;
}


.download-report {
  font-family: BBVA Benton Sans Medium !important;
  font-style: normal !important;
  font-size: 1.125em !important;
  width: 200px !important;
  background-color: #043263 !important;
  border: solid 1px #043263 !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: white !important;
  text-transform: capitalize !important;
}