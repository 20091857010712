@font-face {
  font-family: "BBVA Benton Sans Bold";
  src: local("BentonSansBBVA-Bold"),
    url(./BentonSansBBVA-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "BBVA Benton Sans Book";
  src: local("BentonSansBBVA-Book"),
    url(./BentonSansBBVA-Book.ttf) format("truetype");
}

@font-face {
  font-family: "BBVA Benton Sans Book Italic";
  src: local("BentonSansBBVA-BookItalic"),
    url(./BentonSansBBVA-BookItalic.ttf) format("truetype");
}

@font-face {
  font-family: "BBVA Benton Sans Light";
  src: local("BentonSansBBVA-Light"),
    url(./BentonSansBBVA-Light.ttf) format("truetype");
}

@font-face {
  font-family: "BBVA Benton Sans Medium";
  src: local("BentonSansBBVA-Medium"),
    url(./BentonSansBBVA-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "BBVA Benton Sans Medium Italic";
  src: local("BentonSansBBVA-MediumItalic"),
    url(./BentonSansBBVA-MediumItalic.ttf) format("truetype");
}
