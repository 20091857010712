:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  /* font-family: 'Poppins', serif; */
}

body {
  line-height: 1.35 !important;
  /* font-family: 'Poppins', serif; */
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
}

table {
  width: 100%;
}

.table-striped th {
  font-size: 16px;
  background-color: gray;
  color: black;
} 


.table-striped td {
  padding: 5px;
} 

.filtros {
  /* background-color: #d4e9ff; */
  background-color: #FFFFFF;
  padding: 20px;
}
.filtros-form{
  border: 0px !important;
}
.filtros-form-select {
  width: 200px !important;
  border: 0px !important;
}
.filtro-select-title{
  margin-left: 10px;
}
.btn-filtro{
  text-align: center;
  margin-top: 1rem;
  margin-left: 10px;
}