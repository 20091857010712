@import 'base.css';
@import 'vendors/index.css';
.jodit-container ul li, .jodit-container ol li{
	margin-left: 1rem;
}

.jodit-container span{
	color:  #c0c0c0;
}

.jodit-container p{
    font-family: BBVA Benton Sans Book !important;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}


.editorMkup{
	padding-bottom: 5rem!important;
}
.wrapper-class {
	padding: 1rem 0;
}
.editor-class {
	padding: 1rem 0;
}

.DraftEditor-root:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
}

.DraftEditor-root:hover:before {
    border-bottom: 2px solid #495057;
}

.DraftEditor-root:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #0A8FDC;
    pointer-events: none;
}
.slide {
    width: 100%;
  margin-bottom: 20px;
  background-color: lightblue;
  padding: 20px;
}
.drag {
    background-color: lightblue;
}
.dashboard-info-number {
    display: inline-block;
    text-align: center;
    padding: 20px;
    margin-right: 30px;
    background: #004481;
    color: white;
    border-radius: 16px;
}

.dashboard-info-number h2{
    font-size: 50px;
    font-weight: bold;
}

.dashboard-info-number p{
    font-size: 12px;
}

.forms{
    padding-left: 20px;
    padding-right: 20px;

}

.react-paginate {
    text-align: center;
}
.react-paginate ul {
    display: inline-block;
    padding-left: 18px;
    padding-right: 18px;
    
  }
  
  .react-paginate li {
    display: inline-block;
    cursor: pointer;
    background-color: white;
  }
  .react-paginate li a{
      display: block;
      padding: 10px;
  }

  .react-paginate .selected {
      background-color: #004481;
      color: white;
  }

  hr {
      border-top: 1px;
  }
  .dashboard h1{
    font-size: 30px;
  }
  .dashboard hr {
      border-top: 1px #dbd9d9 solid;
  }
  .nav-header-bbva {
      color: #5BBEFF !important;
      font-size: 18px !important;
      padding: 30px 20px 40px !important;
      text-transform: capitalize !important;
  }
  .cerrar-sesion {
      background-color: transparent;
      color: #5BBEFF;
      font-size: 16px;
      border: none;
      padding: 30px 20px;
  }
  h2 {
      font-size: 24px !important;
  }
  /* .btn-cancelar {
      background-color: white !important;
      border: solid 1px #043263 !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      font-weight: bold !important;
      font-size: 18px !important;
      color: #043263 !important;
      text-transform: capitalize !important;
  } */
  /* .btn-primary {
    background-color: #043263 !important;
    border: solid 1px #043263 !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    font-weight: bold !important;
    font-size: 18px !important;
    color: white !important;
    text-transform: capitalize !important;
  } */

  .help .MuiButton-label{
    text-transform: initial;
  }

  .input-search-bbva {
    border-radius: none !important;
    width: 392px;
    height: 44px;
    background: #F4F4F4;
    border: none;
    padding: 10px;

    font-family: BBVA Benton Sans Book;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color:#666666
  }
  .tabla-bbva {
      color: black;
      font-size: 16px !important;
      margin: 0px 20px;
      width: calc(100% - 40px) !important;
  }
  .tabla-bbva th {
      color: #121212;
      font-size: 16px !important;
      font-weight: normal !important;
      padding: 10px 10px 10px 5px !important;
      border-bottom: 0px !important;
      line-height: 21px !important;
      letter-spacing: 0em !important;
      text-align: left !important;
  }


  .tabla-bbva tr td{
      border: none;
      font-family: BBVA Benton Sans Book !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 21px !important;
      letter-spacing: 0em !important;
      text-align: left !important;
      color: #121212 !important;
  }
/*   .tabla-bbva .item-hover {
      padding: 0px;
    border: none;
} */
/* .tabla-bbva .item-hover td{
    padding: 5px;
    color: black;
    font-size: 16px;
    border-bottom: 0px;
} */
.container-help {
    text-align: center;
}
.container-botones{
    margin: 230px auto !important;
}
.btn-pdf{
    margin-right: 15px !important;
}
.table-assignations-rows{
    height: 50px !important;
    font-family: BBVA Benton Sans Book !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #121212 !important;
}

.table-edit-object{
    font-family: BBVA Benton Sans Bold !important; 
    font-style: normal !important;  
    font-size: 18px !important;  
    line-height: 24px !important;
    color: #1973B8 !important; 
    text-decoration: unset !important;
}

.files-txt1{
    font-size: 17px;
}
.files-txt2{
    color: #076be1;
}
.files-txt3{
    text-align: left;
}
/* ESTILOS GENERALES */
/* .general-label{
    color: black;
    margin-top: 1rem;
    font-weight: bold;
} */
/* .switch-text{
    text-align: right;
    margin-top: 10px;
} */
.imagen-zone{
    background:#DFDFDF;
}
.switch-text-user{
    text-align: left;
    margin-left: 5px;
    margin-top: 9px;
    color: black;
}
/* AYUDA Y SOPORTE */
.text-help{
    text-align: left;
}
.link-help{
    color: #004481;
    font-weight: bold;
    display: inline-block
}

/* DETALLE DE USUARIOS */
.switch-admin{
    margin-right: 10px;
}
.title-user-detail{
    text-transform: uppercase;
    color:#004481;
    font-weight: bold;
    font-size: 16px;
    margin-left: 1rem;
}
.sub-subtitle-user-detail{
    font-size: 10px;
}
.subtitle-user-detail{
    font-weight: bold;
    font-size: 15px;
    color: black;
}
.edit-text{
    color: #1973B8;
    font-size: 15px;
    font-weight: bold;
}

.sub-msg{
    font-family: BBVA Benton Sans Book !important;
    font-style: normal !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #121212 !important;
}
/* DASHBOARD */
.tabla-bbva-dashboard .makeStyles-tableResponsiveMaterial-118{
    color: black;
    font-size: 16px !important;
    margin: 0px 20px;
    width: 100% !important;
    overflow-x:unset;
}

.tabla-bbva-grid{
    margin-right: -30px !important;
}


.tabla-bbva-dashboard th {
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    padding: 10px;
    border-bottom: 0px;
    background: #D3D3D3;
}
.tabla-bbva-dashboard tr {
    border: none;
    color: #121212;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    background-color: #FFFFFF;

}
.tabla-bbva-dashboard tr:nth-child(odd) {background-color: #F4F4F4}

.xyplot-line-chart .rv-xy-plot__inner {
   height: 500px !important;
}

.date-dashboard-picker .MuiFormControl-root .MuiTextField-root   {
        margin-top: 16px;
        background: red !important;
        border-radius: 4px !important;
        padding: 12px !important;
}

.grid-to {
    max-width: 3.333% !important;
}